body {
  margin: 0;
  font-family: "MierA-Regular", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  padding: env(safe-area-inset);
  background: -o-linear-gradient(bottom, #f3f3f3 50%, #282828 50%);
  background: -moz-linear-gradient(bottom, #f3f3f3 50%, #282828 50%);
  background: -webkit-linear-gradient(bottom, #f3f3f3 50%, #282828 50%);
  background: -ms-linear-gradient(bottom, #f3f3f3 50%, #282828 50%);
  background: linear-gradient(to bottom, #f3f3f3 50%, #282828 50%);
}

@font-face {
  font-family: "MierA-Regular";
  src: local("MierA-Regular"),
    url(./assets/fonts/MierA-Regular.woff2) format("opentype");
}
@font-face {
  font-family: "MierA-Book";
  src: local("MierA-Book"),
    url(./assets/fonts/MierA-Book.woff2) format("opentype");
}
@font-face {
  font-family: "MierA-DemiBold";
  src: local("MierA-DemiBold"),
    url(./assets/fonts/MierA-DemiBold.woff2) format("opentype");
}
@font-face {
  font-family: "Cambon-Light";
  src: local("Cambon-Light"),
    url(./assets/fonts/Cambon-Light.woff2) format("opentype");
}
