.ios-peek {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #282828;
  width: 100vw;
  height: 100vh;
}
a {
  text-decoration-line: none;
  color: black;
}
.dark-svg path {
  fill: "#4a4a4a" !important;
}
.custom-light-but {
  color: #fbfbfb;
}
.custom-light-but-2 {
  color: #777;
  border-color: #777;
}
.custom-light-but-2:hover {
  background-color: #777;
  border-color: #777;
  color: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%), 0 4px 10px rgb(119 119 119 / 25%);
}
.link {
  position: relative;
}
.link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}
.link:hover::before {
  visibility: visible;
  transform: scaleX(1);
}
.link:hover {
  cursor: pointer;
  text-decoration: none;
}
.link-dark {
  position: relative;
  color: #4a4a4a;
}
.link-dark-normal {
  color: #4a4a4a;
}
.link-dark-normal:hover {
  color: #4a4a4a;
  text-decoration-color: #4a4a4a;
}
.link-dark::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #4a4a4a;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}
.link-dark:hover::before {
  visibility: visible;
  transform: scaleX(1);
}
.link-dark:hover {
  cursor: pointer;
  text-decoration: none;
  color: #4a4a4a;
}
.main-banner {
  background-color: black;
  height: 100vh;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 50px 0 100px;
}
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  height: 100%;
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  border-radius: 200px;
  border: 1px solid white;
  transition: box-shadow 0.3s, transform 0.3s;
}
/* .avatar:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);
} */
.project {
  width: 100%;
  transition: transform 0.3s;
}
.project:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.edu-card-header {
  font-size: 20px;
  font-family: "MierA-DemiBold";
}
.edu-card-subheader {
  font-size: 15px;
  font-family: "MierA-Regular";
  margin-top: 3px;
}
.edu-card-subheader > span {
  font-weight: normal;
}

.contact-form-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-body {
  font-family: "MierA-Regular";
  height: 200px;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  background-color: transparent;
  color: #4a4a4a;
  border-radius: 10px;
  border: 0px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  resize: none;
  -webkit-appearance: none;
}
.contact-body:hover {
  transform: translateY(-5px);
}
.contact-body:focus {
  outline: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.contact-form-sub-container {
  max-width: 860px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-name {
  font-family: "MierA-Regular";
  line-height: 20px;
  height: 50px;
  width: 70%;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  background-color: transparent;
  color: #4a4a4a;
  border-radius: 40px;
  border-width: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  -webkit-appearance: none;
}
.contact-name:hover {
  transform: translateY(-5px);
}
.contact-name:focus {
  outline: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}
.contact-submit {
  height: 60px;
  width: 60px;
  text-align: center;
  background-color: transparent;
  border-radius: 60px;
  border-width: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: bold;
  -webkit-appearance: none;
}
.contact-submit:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: black;
  color: #fbfbfb;
}
.contact-submit:focus {
  outline: none;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
  transform: translateY(-5px);
}

.error {
  box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.4);
}
.lds-ellipsis-container {
  min-width: 64px;
  min-height: 64px;
  display: flex;
  justify-content: center;
  color: #4a4a4a;
  font-size: 18px;
  font-weight: bold;
  line-height: 64px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #4a4a4a;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

.social-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.social-container > a {
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 30px;
  width: 20px;
  height: 20px;
}
.social-container > a > img {
  transition: transform 0.3s;
}

.social-container > a > img:hover {
  transform: translateY(-5px);
}
.custom-link-anim > img {
  transition: transform 0.3s;
}
.custom-link-anim > img:hover {
  transform: translateY(-5px);
}
.footer-text {
  color: gray;
  text-align: left;
  font-size: 12px;
}
.footer > span {
  text-align: left;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
